import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { cn } from '@/utils';

interface IDepositTermsProps {
  className?: string;
  interestRate: string | number;
  isInterestRateUpTo?: boolean;
  annualFee: string | null;
  cashback: string | null;
  isBlogBankCard?: boolean;
}

export const SavingsAccountsTerms: FC<IDepositTermsProps> = ({
  interestRate,
  isInterestRateUpTo,
  annualFee,
  cashback,
  className,
  isBlogBankCard = false,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'content-start items-start justify-start gap-6 text-center text-grayColor md:flex',
        className,
        isBlogBankCard && 'gap-4',
      )}
    >
      <div className="flex flex-col lg:flex-row lg:items-end lg:gap-1 xl:gap-3">
        {isInterestRateUpTo && (
          <span className="caption text-left text-grey-600 lg:mb-1.5 xl:mb-1">
            {t('global.up_to')}
          </span>
        )}
        <p
          className={cn(
            'text-right h4 xl:text-[36px] lg:leading-9 xl:leading-10 tracking-[0.2px] text-blue-900 md:text-left md:w-[96.6px] xl:w-[130px]',
            isBlogBankCard &&
              'xl:text-[24px] xl:font-semibold xl:leading-7 xl:tracking-[0.2px]',
          )}
        >
          {Number(interestRate).toFixed(2)}%
        </p>
      </div>
      <div
        className={cn(
          'hidden md:block xl:min-w-[130px] text-right md:text-left md:w-[96.6px] xl:w-[130px]',
          isBlogBankCard && 'xl:max-w-[83px] xl:min-w-[83px] xl:pl-2',
        )}
      >
        <p className="caption text-grey-500">{t('global.annual_fee')}</p>
        <p className="body2-bold mt-0.5 text-blue-900">{annualFee}</p>
      </div>
      <div
        className={cn(
          'hidden md:block xl:min-w-[130px] text-right md:text-left md:w-[96.6px] xl:w-[130px]',
          isBlogBankCard && 'xl:max-w-[83px] xl:min-w-[83px] xl:pl-2',
        )}
      >
        <p className="caption text-grey-500">{t('global.cashback')}</p>
        <p className="body2-bold mt-0.5 text-blue-900">
          {cashback
            ? t(`savings-accounts.cashback.${cashback}`)
            : t(`savings-accounts.cashback.no`)}
        </p>
      </div>
    </div>
  );
};
