import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { cn } from '@/utils';

type BadgeDepositType =
  | 'protected'
  | 'available'
  | 'subordinated'
  | 'cashback'
  | 'free-maintenance'
  | 'special';
const badgeVariants = cva(
  'rounded-[32px] px-2 py-0.5 font-inter text-[11px] font-bold leading-[16px] tracking-[0.2px] md:text-[13px] md:leading-[18px] xl:pb-[6px] xl:pt-1 xl:tracking-[0.1px]',
  {
    variants: {
      variant: {
        protected: 'bg-[#33BBFF] text-white',
        available: 'bg-blue-500 text-white',
        subordinated: 'bg-red-150 text-red-500',
        cashback: 'bg-blue-500 text-white',
        'free-maintenance': 'bg-pink-500 text-white',
        special: 'bg-[#44C9C7] text-white',
      },
    },
    defaultVariants: {
      variant: 'protected',
    },
  },
);
interface IBadgeDepositProps extends VariantProps<typeof badgeVariants> {
  text?: string;
  className?: string;
}

const typeMap = new Map<BadgeDepositType, string>([
  ['protected', 'badge_protected'],
  ['available', 'badge_available_online'],
  ['subordinated', 'badge_subordinated'],
  ['cashback', 'badge_cashback'],
  ['free-maintenance', 'badge_free-maintenance'],
  ['special', 'badge_special'],
]);

export const BadgeDeposit: FC<IBadgeDepositProps> = ({
  variant,
  text,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn(badgeVariants({ variant, className }))}>
      {text || t(`deposit_card.${typeMap.get(variant as BadgeDepositType)}`)}
    </div>
  );
};
