import type { AnalyticsData } from '@pickthebank/api-sdk/type/request/auth/login.dto';
import { useRouter } from 'next-translate-routes/router';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';

import { useAnalyticsStore } from '@/store/analyticsStore';

export interface AnalyticsContent {
  analytics: AnalyticsData;
}

interface AnalyticsContextProps {
  analytics: AnalyticsData;
}

const CmsContentContext = createContext<AnalyticsContextProps | undefined>(
  undefined,
);

export const AnalyticsProvider: React.FC<{
  children: ReactNode;
  analytics: AnalyticsData;
}> = ({ children, analytics }) => {
  const { analytics: storedAnalytics, setAnalytics: setStoredAnalytics } =
    useAnalyticsStore((state) => state);
  const router = useRouter();

  const contextValue = useMemo(() => {
    return {
      analytics: storedAnalytics || analytics,
    };
  }, [analytics]);

  useEffect(() => {
    if (router.isReady && analytics) {
      if (
        analytics.fbclid ||
        analytics.gclid ||
        analytics.tracking_type ||
        analytics.utm_medium ||
        analytics.utm_campaign ||
        analytics.utm_source ||
        analytics.subid
      ) {
        setStoredAnalytics(analytics);
      }
    }
  }, []);

  return (
    <CmsContentContext.Provider value={contextValue}>
      {children}
    </CmsContentContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(CmsContentContext);
  if (!context) {
    throw new Error('useFooterLinks must be used within a FooterLinksProvider');
  }
  return context;
};
