export const enum AnalyticsEvent {
  openDeposit = 'openDeposit',
  toggleDetails = 'toggleDetails',
  openOnline = 'openOnline',
  openOnlineModal = 'openOnlineModal',
  openOnlineModalRedirect = 'openOnlineModalRedirect',
  openOnlineModalClose = 'openOnlineModalClose',
  moreInfo = 'moreInfo',
  visitBankSite = 'visitBankSite',
  // search events
  openResidency = 'openResidency',
  openCurrency = 'openCurrency',
  openTerms = 'openTerms',
  openType = 'openType',
  openCashback = 'openCashback',
  openAnnualFee = 'openAnnualFee',
  clickFindDepositButton = 'clickFindDepositButton',
  // how it works button
  clickLearnMore = 'clickLearnMore',
  // show details for deposit
  clickCheckDetails = 'clickCheckDetails',
  clickShowAllDeposits = 'clickShowAllDeposits',
  clickSecurityDeposit = 'clickSecurityDeposit',
  toggleFAQ = 'toggleFAQ',
  toggleFAQLearnMore = 'toggleFAQLearnMore',
  clickHowToChoose = 'clickHowToChoose',

  clickSingUpForFree = 'clickSingUpForFree',
  clickLogin = 'clickLogin',
  clickLogout = 'clickLogout',
  clickSignUp = 'clickSignUp',
  clickSignUpWithEmail = 'clickSignUpWithEmail',

  // footer
  footerClickImprint = 'footerClickImprint',
  footerClickBlog = 'footerClickBlog',

  footerClickHome = 'footerClickHome',
  footerClickAbout = 'footerClickAbout',
  footerClickFAQ = 'footerClickFAQ',
  footerClickRegister = 'footerClickRegister',

  footerClickTermsUse = 'footerClickTermsUse',
  footerClickTermsService = 'footerClickTermsService',
  footerClickPrivacy = 'footerClickPrivacy',

  // compliance
  basicInformationStepCompleted = 'basicInformationStepCompleted',
  addressStepCompleted = 'addressStepCompleted',
  additionalInformationStepCompleted = 'additionalInformationStepCompleted',
  sumsubVerificationCompleted = 'sumsubVerificationCompleted',
  smsVerificationCompleted = 'smsVerificationCompleted',
  complianceFullCompleted = 'complianceFullCompleted',

  // mintos ads
  mintosClickOpenOnline = 'mintosClickOpenOnline',
  mintosClickShare = 'mintosClickShare',
  mintosBlockView = 'mintosBlockView',
}

export const enum AnalyticsCategory {
  main = 'mainPage',
  deposit = 'depositPage',
  compliance = 'compliance',
  savingsAccountsList = 'savingsAccountsList',
}

// Analytics event data model
export interface IAnalyticEvent {
  // action as click to set in ga
  action: AnalyticsEvent;
  // category as group for similar events
  category?: AnalyticsCategory;
  // label as name events in ga
  label?: string;
  // value as boolean state for ga
  value?: number;
  // payload as additional data
  payload?: any;
}

// Analytic service interface
export interface IAnalyticService {
  // Init analytic service
  init(): void;

  sendEvent(event: IAnalyticEvent): Promise<void>;
}
