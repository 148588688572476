import { ComplianceVerificationStatusEnum } from '@pickthebank/api-sdk/index';
import type { GetComplianceVerificationDto } from '@pickthebank/api-sdk/type/response/compliance/verification/get-compliance-verification-status-response.dto';

export const ensureAllVerificationsPassed = (
  verificationArray: GetComplianceVerificationDto[],
): boolean => {
  for (const verification of verificationArray) {
    if (!verification.is_verified) {
      return false;
    }
  }
  return true;
};

export const checkHasVerificationsStatus = (
  verificationArray: GetComplianceVerificationDto[],
  status: ComplianceVerificationStatusEnum,
): boolean => {
  for (const verification of verificationArray) {
    if (verification.resolution === status) {
      return true;
    }
  }
  return false;
};

export const checkAllVerificationsAreApproved = (
  verificationArray: GetComplianceVerificationDto[],
): boolean => {
  console.log(verificationArray);
  for (const verification of verificationArray) {
    if (verification.resolution !== ComplianceVerificationStatusEnum.APPROVED) {
      return false;
    }
  }
  return true;
};

export const shouldVerifyVerification = (
  type: string,
  verifications: GetComplianceVerificationDto[],
): boolean => {
  const verification = verifications.find((v) => v.type === type);
  if (verification) {
    return !verification.is_verified;
  }
  return false;
};

export const getVerificationByType = (
  type: string,
  verifications: GetComplianceVerificationDto[],
) => {
  return verifications.find((v) => v.type === type);
};
