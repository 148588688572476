import { type FC } from 'react';

import { useSearchStore } from '@/store';
import type { IDepositTerm } from '@/store/storeSearch.types';
import { cn } from '@/utils';

import { LazyLoaded } from '../LazyLoaded';
import {
  CountryModal,
  CountrySelect,
  CurrencyModal,
  CurrencySelect,
  TermsModal,
  TermsSelect,
} from './parts';

interface ISearchBarProps {
  className?: string;
  showCountry?: boolean;
}

export const SearchDynamic: FC<ISearchBarProps> = ({
  className,
  showCountry = true,
}) => {
  const { terms, setTerms } = useSearchStore((state) => state);

  const handleTermsChange = (_terms: IDepositTerm[]) => {
    setTerms(_terms);
  };

  return (
    <div className={className}>
      <div className={cn('grid-cols-3 gap-3 hidden md:grid')}>
        <LazyLoaded>
          {showCountry && (
            <CountrySelect
              className="w-full shrink-0 rounded-xl border border-grey-200 bg-white "
              inputClassName={cn('rounded-xl w-full')}
              buttonClassName="min-w-full rounded-xl md:min-w-full lg:min-w-full xl:min-w-full px-4"
              dropdownClassname="rounded-xl"
            />
          )}
          <CurrencySelect
            className="w-full shrink-0 rounded-xl border border-grey-200 bg-white"
            inputClassName={cn('rounded-xl w-full')}
            buttonClassName="min-w-full md:min-w-full lg:min-w-full xl:min-w-full px-4"
            dropdownClassname="rounded-xl"
          />
          <TermsSelect
            terms={terms}
            className="w-full shrink-0 rounded-xl border border-grey-200 bg-white"
            dropdownClassname="w-[247px] rounded-xl"
            inputClassName={cn('rounded-xl w-full')}
            buttonClassName="min-w-full md:min-w-full lg:min-w-full xl:min-w-full px-4"
            onChange={handleTermsChange}
          />
        </LazyLoaded>
      </div>
      <div className="flex flex-col gap-3 md:hidden">
        <LazyLoaded>
          {showCountry && (
            <CountryModal
              className="rounded-xl border border-grey-200 bg-white"
              buttonClassName="px-4"
            />
          )}
          <div className="grid grid-cols-2 gap-3">
            <CurrencyModal
              className="rounded-xl border border-grey-200 bg-white"
              shouldShowCurrencyCode
            />
            <TermsModal
              terms={terms}
              onChange={handleTermsChange}
              className="rounded-xl border border-grey-200 bg-white px-4"
            />
          </div>
        </LazyLoaded>
      </div>
    </div>
  );
};
