import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import router from 'next-translate-routes/router';
import type { FC } from 'react';
import React from 'react';

import { Icon, LazyLoaded } from '@/components';
import { useHeader } from '@/hooks/useHeader';
import useUserInformation from '@/hooks/useUserInformation';
import { useAuthStore } from '@/store';
import { useModalsStore } from '@/store/modalsStore';
import type { LangSwitcherDataKey } from '@/utils';
import { cn, LangSwitcherData } from '@/utils';
import { isMobileApp } from '@/utils/isMobileApp';

interface INavbarMobileProps {
  className?: string;
  onClose: () => void;
  toggleLauguageMenu: () => void;
}

export const NavbarMobile: FC<INavbarMobileProps> = ({
  className,
  onClose,
  toggleLauguageMenu,
}) => {
  const { t } = useTranslation();
  const { token, isTestUser } = useAuthStore((state) => state);
  const { showedUsername, isVerified } = useUserInformation();
  const { setIsBurgerMenuOpen } = useModalsStore((state) => state);

  const { links } = useHeader();

  const handleLanguageClick = () => {
    toggleLauguageMenu();
  };

  const closeBurgerMenu = () => {
    setIsBurgerMenuOpen(false);
  };

  return (
    <ul className={cn('mx-5 pb-0 pt-2', className)}>
      <LazyLoaded>
        {token && isTestUser && (
          <>
            <Link href="/profile/portfolio" onClick={closeBurgerMenu}>
              <div className="flex items-center justify-between py-3">
                <div className="flex shrink items-center break-all">
                  <Icon
                    name="AvatarLogo"
                    className="size-10 text-transparent"
                  />
                  <div className="ml-2">
                    <p className="body2-bold tracking-[0.2px]">
                      {showedUsername}
                    </p>
                    {isVerified ? (
                      <p className="body3 tracking-[0.2px] text-grey-800">
                        {t('profile.verified_account')}
                      </p>
                    ) : (
                      <p className="body3 tracking-[0.2px] text-red-500">
                        {t('profile.account_not_verified')}
                      </p>
                    )}
                  </div>
                </div>
                <Icon
                  name="chevron"
                  className="size-5 shrink rotate-90 text-grey-600"
                />
              </div>
            </Link>
            <li className="flex py-4 last:pb-0">
              <button
                type="button"
                onClick={() => {
                  router.push('/profile/portfolio');
                  closeBurgerMenu();
                }}
                className="w-full"
              >
                <div className="flex w-full justify-between">
                  <p
                    className={cn(
                      'body2',
                      router.pathname === '/profile/portfolio'
                        ? 'body2-bold text-blue-900'
                        : 'body2 text-grey-800',
                    )}
                  >
                    {t('header.portfolio')}
                  </p>
                </div>
              </button>
            </li>
          </>
        )}
        <li className="flex py-4">
          <button
            type="button"
            onClick={() => {
              router.push('/favourites');
              closeBurgerMenu();
            }}
            className="w-full"
          >
            <div className="flex w-full justify-between">
              <p
                className={cn(
                  'body2',
                  router.pathname === '/favourites'
                    ? 'body2-bold text-blue-900'
                    : 'body2 text-grey-800',
                )}
              >
                {t('header.favorites')}
              </p>
            </div>
          </button>
        </li>
      </LazyLoaded>
      <hr className="my-2 bg-grey-200" />
      {!isMobileApp() && (
        <>
          {links.map((link) => (
            <li className="flex py-4" key={link.title}>
              <Link href={link.path} onClick={onClose} className="w-full">
                <div className="flex w-full justify-between">
                  <p
                    className={cn(
                      'body2 tracking-[0.2px]',
                      router.pathname === link.path
                        ? 'body2-bold text-blue-900'
                        : 'body2 text-grey-800',
                    )}
                  >
                    {link.title}
                  </p>
                </div>
              </Link>
            </li>
          ))}
          <hr className="my-2 bg-grey-200" />
        </>
      )}
      <LazyLoaded>
        <button
          type="button"
          onClick={() => handleLanguageClick()}
          className="w-full py-4"
        >
          <div className="flex w-full content-start overflow-hidden">
            <div className="flex w-full justify-between">
              <p className="body2 tracking-[0.2px] text-grey-800">
                {t(`header.language`)}
              </p>
              {router.locale && (
                <Image
                  src={
                    LangSwitcherData[router.locale as LangSwitcherDataKey]
                      .flagImg
                  }
                  alt="image"
                  className="h-4 w-5"
                />
              )}
            </div>
          </div>
        </button>
      </LazyLoaded>
      {token && isTestUser && (
        <Link href="/profile" onClick={onClose} className="w-full">
          <div className="flex w-full justify-between py-4">
            <p
              className={cn(
                'body2 tracking-[0.2px]',
                router.pathname === '/profile'
                  ? 'body2-bold text-blue-900'
                  : 'body2 text-grey-800',
              )}
            >
              {t('header.settings')}
            </p>
          </div>
        </Link>
      )}
      <hr className="my-2 bg-grey-200" />
      {isMobileApp() && (
        <Link href="/mobile-app/faq" onClick={onClose} className="w-full">
          <div className="flex w-full justify-between py-4">
            <p
              className={cn(
                'body2 tracking-[0.2px]',
                router.pathname === '/profile'
                  ? 'body2-bold text-blue-900'
                  : 'body2 text-grey-800',
              )}
            >
              {t('global.faq_and_support')}
            </p>
          </div>
        </Link>
      )}
    </ul>
  );
};
